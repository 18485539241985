import styled from "styled-components";

export const FrontpageStyle = styled.section`
margin: 2vh 0vw;
	background-color: rgba(255, 255, 255, 0.4);
	width: 87%;
	height: 83%;
	display: grid;
	border-radius: 10px;
	padding: 10px;
	margin-left: 2vw;
	margin-bottom: 2vh;

    h1 {
		color: green;
		font-size: 35px;
		text-align: center;
	}
	p {
		color: black;
		font-size: 20px;
	}
	.server-info {
		font-size: 18px;
		margin-top: 20px;
	}
	.server-info ul {
		list-style-type: none;
		padding: 0;
	}
	.server-info li {
		margin: 10px 0;
	}
	.server-ip {
		font-size: 20px;
	}

	.sliderSec{
        display: grid;
        grid-template-columns: 10% 80% 10%;
        width: 100%;
            height: 60vh;
        background-size: cover;
        margin: 0;
        padding: 0;

    }

	@media screen and (min-width: 768px) {
		margin: 2vh 0vw;
	background-color: rgba(255, 255, 255, 0.4);
	width: 100%;
	height: 94%;
	display: grid;
	border-radius: 10px;
	padding: 10px;
	margin-right: 10px;

    h1 {
		color: green;
		font-size: 35px;
		text-align: center;
	}
	p {
		color: black;
		font-size: 20px;
	}
	.server-info {
		font-size: 18px;
		margin-top: 20px;
	}
	.server-info ul {
		list-style-type: none;
		padding: 0;
	}
	.server-info li {
		margin: 10px 0;
	}
	.server-ip {
		font-size: 20px;
	}

	.sliderSec{
        display: grid;
        grid-template-columns: 10% 80% 10%;
        width: 100%;
            height: 60vh;
        background-size: cover;
        margin: 0;
        padding: 0;
		margin-bottom: 2vh;

    }
	}
`