import React from 'react'
import { FooterStyle } from './Footer.style'

function Footer() {
  return (
    <FooterStyle>
                <p>Copyright © 2023 Hul I Jorden</p>
    {/* <a style="width: 8%;" href="https://kode4.dk"> Philip Thomsen</a> */}
    </FooterStyle>
  )
}

export default Footer