import React from 'react'
import { CommandsStyle } from './Commands.style'

function Commands() {
  return (
    <CommandsStyle>
            <h1>Minecraft kommandoer</h1>
            <h3>Disse kommandoer er gode at kende til på serveren</h3>

<div class="command">
    <h2>/cbuy</h2>
    <p>Åbner første command shop</p>
    <code>/cbuy</code>
</div>

<div class="command">
    <h2>/shop</h2>
    <p>Åbner server shoppen</p>
    <code>/shop</code>
</div>

<div class="command">
    <h2>/tempfly</h2>
    <p>Gør så du kan flyve, hvis du har nok tid. </p>
    <code>/tempfly</code>
</div>

<div class="command">
    <h2>/warp</h2>
    <p>Telepotere dig til en lokation</p>
    <code>/warp Afrika</code>
</div>

<div class="command">
    <h2>/lottery buy</h2>
    <p>Køb billet til server lotto</p>
    <code>/lottery buy 1</code>
</div>

<div class="command">
    <h2>/lottery status</h2>
    <p>Se forskllige oplysninger om lotteriet</p>
    <code>/lottery status</code>
</div>

<div class="command">
    <h2>/pvp</h2>
    <p>Slå pvp fra for dig selv så andre spillere ikke kan dræbe dig</p>
    <code>/pvp</code>
</div>
    </CommandsStyle>

  )
}

export default Commands