import { styled } from "styled-components";

export const CommandsStyle = styled.section`
margin: 2vh 0vw;
	background-color: rgba(255, 255, 255, 0.4);
	width: 100%;
	height: 94%;
	display: grid;
	border-radius: 10px;
	padding: 10px;
	margin-right: 10px;
	padding-bottom: 2vh;

        h1 {
            text-align: center;
			
        }

		h3{
			text-align: center;
			font-weight: 400;
		}
        .command {
            background-color: #f0f0f0;
            border: 1px solid #ccc;
            padding: 10px;
            margin: 10px;
            border-radius: 5px;
        }
`