import { styled } from "styled-components";

export const RulesStyle = styled.section`
margin: 2vh 0vw;
	background-color: rgba(255, 255, 255, 0.4);
	width: 100%;
	height: 94%;
	border-radius: 10px;
	padding: 10px;
	margin-right: 10px;
	margin-bottom: 15%;

    h1 {
		color: green;
		font-size: 40px;
		text-align: center;
        margin-bottom: 5vh;
	}

	p {
		color: black;
		font-size: 20px;
        margin-bottom: 5vh;
	}
    h2{
        margin-bottom: 5vh;
    }
	ol{
        margin-left: 2vw;
        li{
            color: black;
		font-size: 18px;   
        }
    }
`