import React from 'react'
import Frontpage from '../Pages/Frontpage/Frontpage'
import { Route, Routes } from 'react-router-dom'
import Rules from '../Pages/Rules/Rules'
import Commands from '../Pages/Commands/Commands'
import Server from '../Pages/Server/Server'
import OutputCalc from '../Pages/OutputCalc/OutputCalc'

function Approuter() {
  return (
    <Routes>
        <Route index element={<Frontpage />}/>
        <Route path='/regler' element={<Rules/>}></Route>
        <Route path='/kommandoer' element={<Commands/>}></Route>
        <Route path='/server' element={<Server/>}></Route>
        <Route path='/output' element={<OutputCalc/>}></Route>
    </Routes>
  )
}

export default Approuter