import { styled } from "styled-components";

export const SidebarTwoStyle = styled.article`
margin: 2vh 2vw;
	background-color: rgba(255, 255, 255, 0.4);
	width: 93%;
	height: max-content;
	padding-bottom: 1vh;
	border-radius: 10px;
	margin-right: 10px;
	h2{
		padding-top: 1vh;
    height: 5vh;
	padding-left: 1vw;
		text-align: center;
	}
  h3{
    height: 5vh;
    font-size: 15px;
    text-align: center;
  }
div{
	margin-left: 0.5vw;
display: flex;
flex-wrap: wrap;
	width: 93%;
	height: min-content;
	overflow: auto;
	p{
		font-size: 15px;
		background-color: green;
		margin: 5px 5px;
		padding: 5px 5px;
		border-radius: 10px;
		height: min-content;
	}
}
`