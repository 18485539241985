import React, { useState } from 'react'
import { OutputCalcStyle } from './OutputCalc.style'
import { useForm } from 'react-hook-form'

function OutputCalc() {
    const { register, handleSubmit, formState: { errors } } = useForm() 
    const [calc, setCalc] = useState(0)
    const [price, setPrice] = useState(0)
    const [crop, setCrop] = useState("")
    const [stackable, setStackable] = useState(false)


    const submitForm = (data) => {
        console.log(data);
        setCalc(data.num)
        setCrop(data.crop)
        setPrice(data.price)
        setStackable(data.stack)
    }
  return (
    <OutputCalcStyle>
<h1>Farm output udregner</h1>
<form onChange={handleSubmit(submitForm)} onSubmit={handleSubmit(submitForm)}>
<label htmlFor="">Hvad farmer du?</label>
<input type="text" {...register('crop')}/>

<label htmlFor="">Kan stackes</label>
<input type="checkbox" name="" id="" {...register('stack')}/>

<label htmlFor="">hvad sælges en for?</label>
<input type="number" name="" id=""  {...register('price')}/>

<label htmlFor="">Hvor mange {crop} gir den pr minut?</label>
<input type="number" name="" id="" {...register('num')} />
</form>

<h2>Pr minut</h2>
<p>{crop}: {(calc).toLocaleString()} {stackable ? "(" + ((calc)/ 64).toFixed(1) + " stacks)" : null}</p>
<p>Penge: {((calc)*price).toLocaleString()}$</p>

<h2>Pr time</h2>
<p>{crop}: {(calc * 60).toLocaleString()} {stackable ? "(" + ((calc * 60)/ 64).toFixed(1) + " stacks)" : null}</p>
<p>Penge: {((calc * 60)*price).toLocaleString()}$</p>

<h2>Pr dag</h2>
<p>{crop}: {((calc * 60)*24).toLocaleString()} ({stackable ? ((((calc * 60)/64)*24)/54).toFixed(1) : (((calc * 60)*24)/54).toFixed(1)} Dobblechests)</p>
<p>Penge: {(((calc * 60)*24) * price).toLocaleString()}$</p>

    </OutputCalcStyle>

  )
}

export default OutputCalc