import React, { useState } from 'react'
import { FrontpageStyle } from './Frontpage.style'
import rightArrow from '../../assets/icon-arrow-right.svg'
import leftArrow from '../../assets/icon-arrow-left.svg'

import earth1 from '../../assets/earth1.png'
import earth2 from '../../assets/earth2.png'
import earth3 from '../../assets/earth3.png'
import earth4 from '../../assets/earth4.png'
import earth5 from '../../assets/earth5.png'
import spawn1 from '../../assets/spawn1.png'
import spawn2 from '../../assets/spawn2.png'
import spawn3 from '../../assets/spawn3.png'
import spawn4 from '../../assets/spawn4.png'
import spawn5 from '../../assets/spawn5.png'
import spawn6 from '../../assets/spawn6.png'
import spawn7 from '../../assets/spawn7.png'
import spawn8 from '../../assets/spawn8.png'
import spawn9 from '../../assets/spawn9.png'
import { useMediaQuery } from 'react-responsive'
function Frontpage() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    //Array med alle slider billederne
    const imgArray = [
      earth1,
      earth2,
      earth3,
      earth4,
      earth5,
      spawn1,
      spawn2,
      spawn3,
      spawn4,
      spawn5,
      spawn6,
      spawn7,
      spawn8,
      spawn9,
    ]
  
    const [slideNum, setSlideNum] = useState(0)
  
    //Hvis slideNum er større end der er billeder i listen, nulstilles slideNum hooket til 0
    if (slideNum > imgArray.length - 1) {
      setSlideNum(0)
    }
  
    if (slideNum < 0) {
      setSlideNum(2)
    }
  return (
    <FrontpageStyle>
                        <h1>Velkommen til Hul i jorden! 🌎</h1>
                     {!isMobile ? <>   <section style={{backgroundImage: `url(${imgArray[slideNum]})`}} className='sliderSec'>
        {/* Trækker 1 fra hvordan slideNum statet er pt */}

      <div></div>      
              {/* ligger 1 til hvordan slideNum statet var før */}      
      
      </section>
      <article style={{
        paddingLeft: '25vw',
        }}>
        <button style={{
          width: 'fitContent',
          borderRadius: '50px',
          backgroundColor: '#ffffff00',
          border: 'none',
          padding: '0vh 1.5vw',
        }} onClick={() => setSlideNum(slideNum - 1)}><img src={leftArrow} alt="left arrow" /></button>
        <button style={{
          width: 'fitContent',
          borderRadius: '50px',
          backgroundColor: '#ffffff00',
          border: 'none',
          padding: '0vh 1.5vw',
        }} onClick={() => setSlideNum(prev => prev + 1)}><img src={rightArrow} alt="right arrow" /></button>
      </article></> : null}
<p id="server-ip-text" class="server-ip"><strong>IP:</strong> mc.hulijorden.dk</p>
    </FrontpageStyle>
  )
}

export default Frontpage