import React from 'react'
import { HeaderStyle } from './Header.style'
import logo from '../../assets/Whitelogo.png'
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';

function Header() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <HeaderStyle>

     <img src={logo} alt="Logo"/>

<nav>
    <ul>
      <li><NavLink to={'/'}>Forside</NavLink></li>
      <li><a class="button" target="_blank" href="http://spil.hulijorden.dk:11099/">Webmap</a></li>
      <li><a href="https://discord.gg/7x62xYvA">Discord</a></li>
{     !isMobile ? <>
      <li><NavLink to={'/regler'}>Regler</NavLink></li>
      <li><NavLink to={'/kommandoer'}>Kommandoer</NavLink></li>
      </> : null}
      {/* <li><NavLink to={'/server'}>Serveren</NavLink></li> */}
    </ul>
</nav>
    </HeaderStyle>
  )
}

export default Header
