import styled from "styled-components";

export const FooterStyle = styled.footer`
height: 7vh;
background-color: green;
border-radius: 10px;
display: flex;
margin-top: 1.5vh;

p{
font-size: 15px;
color: white;
margin-top: 2.5vh;
margin-left: 2vw;
}
`