import { styled } from "styled-components";

export const ServerStyle = styled.section`
margin: 2vh 0vw;
	background-color: rgba(255, 255, 255, 0.4);
	width: 100%;
	height: 94%;
	display: grid;
    grid-template-columns: 5% 75% 5%;
	border-radius: 10px;
	padding: 10px;
	margin-right: 10px;

    .switches{
        margin-top: 50vh;
        height: 6%;
        border-radius: 50px;
        border: none;
    }

    img{
        height: 100%;
        width: 100%;
    }

`