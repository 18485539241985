import React, { useState } from 'react'
import { ServerStyle } from './Server.style'
import earth1 from '../../assets/earth1.png'
import earth2 from '../../assets/earth2.png'
import earth3 from '../../assets/earth3.png'
import earth4 from '../../assets/earth4.png'
import earth5 from '../../assets/earth5.png'
import spawn1 from '../../assets/spawn1.png'
import spawn2 from '../../assets/spawn2.png'
import spawn3 from '../../assets/spawn3.png'
import spawn4 from '../../assets/spawn4.png'
import spawn5 from '../../assets/spawn5.png'
import spawn6 from '../../assets/spawn6.png'
import spawn7 from '../../assets/spawn7.png'
import spawn8 from '../../assets/spawn8.png'
import spawn9 from '../../assets/spawn9.png'
function Server() {
    const [img, setImg] = useState(1)
    const assets = [
        earth1,
        earth2,
        earth3,
        earth4,
        earth5,
        spawn1,
        spawn2,
        spawn3,
        spawn4,
        spawn5,
        spawn6,
        spawn7,
        spawn8,
        spawn9,
    ]
    let num = 0;
    
    

  return (
    <ServerStyle>
                    <button onclick={() => setImg(3)} class="switches">s</button>
            <img id="slideImg" src={assets[img]} alt="Image 1"/>
            <button class="switches" onclick={() => setImg(5)}>s</button>
    </ServerStyle>
  )
}

export default Server