import React, { useEffect, useState } from 'react'
import { SidebarTwoStyle } from './SidebarTwo.style'

function SidebarTwo() {
  const [data, setData] = useState()
  const [persons, setPersons] = useState(0)
  let num = 0
const bots = [
  'Dad Bot',
  'ServerStats',
  'Minechat',
  'Ticket Tool',
  'MEE6'
]
  useEffect(() => {
    const url = "https://discord.com/api/guilds/932363367929249792/widget.json";
    
        fetch(url)
          .then(response => response.json())
          .then(data => {
            // console.log(data.members);
            setData(data.members)
          
          })
          .catch(error => {
            console.error(error);
          });
        }, [])

        
        useEffect(() => {
          setTimeout(() => {
            setPersons(num)
          }, 200);
        }, [data])
  return (
<SidebarTwoStyle>
<h2>{data ? 'Discord 🟢' : null}</h2>
<h3>{persons} personer online</h3>
  <div>
    {data ? 
      data.map((item, index) =>{
        if (item) {
          if (!bots.includes(item.username)) {
            num = num + 1
          }
        }    
      return(
       item ? !bots.includes(item.username)  ?  <p>{ item.username }</p> : null : null 
      )

    })
   : null }
  </div>
</SidebarTwoStyle>

  )
}

export default SidebarTwo