import React from 'react'
import { RulesStyle } from './Rules.style'

function Rules() {
  return (
    <RulesStyle>
            <h1>Velkommen til Hul i jorden</h1>
<p>På Hul i jorden, ønsker vi at skabe et inkluderende sted for alle. For at opretholde en positiv oplevelse, har vi nogle regler, som alle forventes at respektere:</p>
<h2>Serverregler:</h2>
<ol>
  <li>Dit brugernavn er dit ansvar.</li>
  <li>Overvej konsekvenserne, før du handler eller udfører noget upassende.</li>
  <li>Du er velkommen til at spille på flere brugere, men husk, at reglerne gælder for dem alle.</li>
  <li>Enhver form for spam, racisme eller reklame fører til en udelukkelse (ban).</li>
  <li>Undlad at spørge om OP, ranks eller gratis ting</li>
  <li>Tal høfligt og hold en respektfuld tone i chatten.</li>
  <li>Spillereglerne giver dig ret til at spille på serveren, men det er ikke en ubetinget rettighed.</li>
  <li>Staff har altid det sidste ord.</li>
  <li>Vi tillader ikke brug af hacks eller andet, der giver en unfair fordel i spillet.</li>
  <li>Optifine er tilladt.</li>
</ol>
    </RulesStyle>

  )
}

export default Rules