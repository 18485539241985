import { styled } from "styled-components";

export const OutputCalcStyle = styled.section`
margin: 2vh 0vw;
	background-color: rgba(255, 255, 255, 0.4);
	width: 100%;
	height: 86%;
	border-radius: 10px;
	padding: 10px;
	margin-right: 10px;
	margin-bottom: 15%;
    text-align: center;
    h1 {
		color: green;
		font-size: 35px;
		text-align: center;
	}
input{
    font-size: 20px;
}
    label{
        font-size: 20px;
        margin-top: 5vh;
        display: block;
    }
    h2{
        font-size: 30px;
        margin-top: 5vh;
    }
    p{
        font-size: 20px;
    }
`