import React, { useEffect, useState } from 'react'
import { SidebarOneStyle } from './SidebarOne.style'
import image from '../../assets/minecraft2.png'

function SidebarOne() {
  const [data, setData] = useState()

  useEffect(() => {
    const url = "https://api.mcsrvstat.us/3/mc.hulijorden.dk";
    
        fetch(url)
          .then(response => response.json())
          .then(data => {
            // console.log(data.players.list);
            setData(data.players.list)
          })
          .catch(error => {
            console.error(error);
          });
        }, [])
    
    
    
  return (
<SidebarOneStyle>
<h2>{data ? 'Server 🟢' : 'Server'}</h2>
<h3>{data ? data.length : 0}/10 spillere online</h3>
  <div>
  {data ? 
      data.map((item, index) =>(
      <p>{ item.name }</p>
      ))
   : '' }
  </div>
</SidebarOneStyle>

  )
}

export default SidebarOne